<template>
    <div class="popup">
        <div class="box">
            <div class="popTitle popTitleArea">
                <p>{{ $t("side.patient.init.button") }}</p>
                <div class="buttonArea">
                    <button class="x" @click="closePopup(false)"><i class="micon">close</i></button>
                </div>
            </div>
            <div class="popupContentArea">
                <PatientListCompo :isStep1="true" :initSelect="true"/>
                <span class="noticeText">{{ $t("page.patient.list.caution.limit") }}</span>
                <div class="btnW popBtn">
                    <button class="btn default" @click="closePopup(false)" v-ripple>{{ $t("common.button.cancel") }}</button>
                    <button class="btn solid" @click="patientSelected()" v-ripple>{{ $t("page.patient.list.button.select") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    middleware: "auth",
    components: {
        PatientListCompo: () => import('../components/patientListCompo.vue'),
    },
    props: {},
    data() {
        return {};
    },
    watch: {},
    created() {},
    mounted() {
    },
    destroyed() {},
    methods: {
        closePopup(progress) {
            this.$emit('closePopup', progress);
        },
        patientSelected(){
            const selectedTargetInfo = JSON.parse(localStorage.getItem("selectedTargetInfo"));
            if(selectedTargetInfo) {
                this.$emit('selectedTargetInfo', selectedTargetInfo)
                this.closePopup(false);
            }else{
                this.showPopup(this.$t('page.patient.list.msg.error.select'))
            }
            
        }
    },
    computed:{},
};
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";

.popup {
    .box{
        max-height: 95vh;
        .x{position: static;}
        .popTitle{
            display:flex; justify-content: space-between; align-items: center;
            p{
                display:flex; justify-content: flex-start; align-items: center;
                i{margin-right: 8px;}
            }
        }
        .cont{
            >li{
                margin-bottom: 24px;
                .titArea{
                    display: flex; justify-content: space-between; align-items: center; margin-bottom: 8px;
                    .tit{margin-bottom: 0;}
                }
                ul.contArea{
                    padding-left: 0;
                    li{
                        display: flex; align-items: center; padding: 0 0 4px 8px; font-size: 14px;
                        p{width: 30%;}
                        span{width: 70%; padding: 0 10px; background: #fbfbfb; border: 1px solid #e5e5e5; border-radius: 4px; height: 30px; line-height: 30px; color: #111 !important; text-align: center;}
                    }
                }
                .compInfo{
                    font-size: 14px; padding:20px 10px; background: #fbfbfb; border: 1px solid #e5e5e5; border-radius: 4px; display: flex; flex-direction: column; flex-wrap: nowrap; justify-content: flex-start; align-items: center;
                    img{margin: 0 auto 10px auto;}
                    a{color: inherit;}
                    ul{
                        display:flex; justify-content: space-between; align-items: center; gap: 8px; margin-bottom: 10px;
                        li{
                            &::after{content:'|'; padding-left: 4px;}
                            &:last-child::after{content:''; padding-left: 0;}
                            span{padding-right: 4px; font-weight: bold;}
                        }
                    }
                }
                &.copyright{font-size: 14px; text-align:center; margin-bottom: 0; color:#777;}
            }
            
        }
    }
}

::v-deep .lay-root.thema-default{
    background-color: var(--color-point) !important;
    transition:box-shadow 0.5s,opacity 0.5s;
    &:hover{
        opacity: 0.85; box-shadow:0 4px 7px rgba(0,0,0,0.1);
    }
}

.noticeText{display:block; font-size: 12px; color: var(--color-point); margin-left: 24px; margin-bottom: 24px;}
</style>